code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.linkBase {
  stroke: #312f38 !important;
  stroke-width: 2px;
}
.nodeBase,
.leafNodeBase {
  stroke: none !important;
}

.pg-viewer-wrapper {
  overflow: initial !important;
}

.pg-viewer-wrapper .pdf-canvas {
  text-align: center;
}

.pg-viewer-wrapper .spreadsheet-viewer,
.pg-viewer-wrapper .unsupported-message,
.pg-viewer,
#pg-viewer {
  color: #000;
}

#id-home-assignments {
  width: 0px;
}
